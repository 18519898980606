import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useStore } from "vuex";
import figureFormatter from "@/services/utils/figureFormatter";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}
const pdfPrinter = () => {
    const store = useStore();
    const {commaFormat} = figureFormatter();
    const {getHeader, getFooter} = pdfHeaderFooter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, incentivePaymentData, isReport = false) => {
        var doc = {
            pageSize: 'A4',
            pageMargins: [10, 100, 10, 80],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(incentivePaymentData, isReport),

            styles: {
                header: {
                    fontSize: 24,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 8,
                font: 'SulaimanLipi'
            },

            info: {
                title: `${isReport ? 'Incentive Payment Report' : 'Draft Incentive Payment'}`,
            }
        }

        pushIntoTable(doc, incentivePaymentData, isReport)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (incentivePaymentData, isReport) => {
        return [
            {
                text: `${isReport ? 'Incentive Payment Report' : 'Draft Incentive Payment'}`,
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                fontSize: 12,
                decoration: 'underline'
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    body: [],
                    widths: customWidth(isReport)
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const customWidth = (isReport = false) => {
        if (!isReport){
            return [ '12%', '13%','15%', '10%', '10%', '10%', '10%', '10%', '10%' ];
        }
        return [ '10%', '10%', '10%','10%', '10%', '10%', '10%', '10%', '10%', '10%' ]
    }

    const pushIntoTable = (doc, incentivePaymentData, isReport) => {
        const tableItems = Array.isArray(incentivePaymentData.data.data)
            ? incentivePaymentData.data.data
            : Object.values(incentivePaymentData.data.data);
        const tableHeader = getTableHeader(isReport);
        doc.content[1].table.body.push(tableHeader);

        tableItems.forEach((item, i) => {
            const rowData = getRowData(item, i, isReport);
            doc.content[1].table.body.push(rowData);

            const detailsRow = getRowDetails(item.item_details || '', isReport);
            doc.content[1].table.body.push(detailsRow);
        });

        const getLastRow = getTotal(incentivePaymentData.data.total, isReport)
        doc.content[1].table.body.push(getLastRow);
    }

    const getRowDetails = (itemDetails, isReport) => {
        const colSpanValue = isReport ? 10 : 9;
        const emptyCells = isReport ? [{}, {}, {}, {}, {}, {}, {}, {}, {}] : [{}, {}, {}, {}, {}, {}, {}, {}];

        return [
            {
                text: itemDetails,
                colSpan: colSpanValue
            },
            ...emptyCells
        ];
    };

    const getTableHeader = (isReport) => {
        let tableHeader = [
            {text: 'Invoice Date'},
            {text: 'Invoice No'},
            {text: 'Patient Name (ID)'},
            {text: 'Total Bill', alignment: 'right'},
            {text: 'Total Discount', alignment: 'right'},
            {text: 'Net Bill', alignment: 'right'},
            {text: 'Total Incentive', alignment: 'right'},
            {text: 'Referrer Discount', alignment: 'right'},
            {text: 'Net Incentive', alignment: 'right'},
        ];

        if (isReport) {
            tableHeader.unshift({text: 'Referrer'});
        }

        return tableHeader;
    }

    const getRowData = (item, i, isReport) => {

        const tableRow = [
            {text: item.date},
            {text: item.bill_number},
            {text: item.contact_profile.full_name + '(' + item.contact_profile.patient_id + ')' },
            {text: commaFormat(item.subtotal_amount), alignment: 'right'},
            {text: commaFormat(item.total_discount), alignment: 'right'},
            {text: commaFormat(item.total_amount), alignment: 'right'},
            {text: commaFormat(item.incentive_amount), alignment: 'right'},
            {text: commaFormat(item.referrer_discount_amount), alignment: 'right'},
            {text: commaFormat(item.net_incentive), alignment: 'right'},
       ]

        if (isReport) {
            tableRow.unshift({text: item.contact_referrer.full_name + '(' + item.contact_referrer.patient_id + ')'});
        }

        return tableRow
    }

    const getTotal = (totalValue, isReport) => {
        const colSpanValue = isReport ? 4 : 3;
        const emptyCells = isReport ? [{}, {}, {}] : [{}, {}];

        const lastRow = [
            {
                text: 'Total',
                alignment: 'right',
                colSpan: colSpanValue
            },

                ...emptyCells,

            {
                text: totalValue.total_invoice,
                alignment: 'right'
            },

            {
                text: totalValue.total_discount,
                alignment: 'right'
            },

            {
                text: totalValue.net_bill,
                alignment: 'right'
            },

            {
                text: totalValue.total_incentive,
                alignment: 'right'
            },

            {
                text: totalValue.total_referrer_discount,
                alignment: 'right'
            },

            {
                text: totalValue.net_incentive,
                alignment: 'right'
            },
        ]

        return lastRow
    }

    return {
        exportToPDF
    }

}

export default pdfPrinter;